import React, { useEffect, useState } from "react";
import PageTitleContainer from "../../Components/PageTitleContainer/PageTitleContainer";
import backgroundImage from "./restaurant.jpg";
import { Grid, Card, CardActions, CardContent, Button, Typography, Modal, Box } from "@mui/material";
import dumplingOne from "./dumpling1.jpg";
import menuOne from "./menu1.jpg";
import menuTwo from "./menu2.jpg"
import "./Locations.css";

const Locations = () => {
  const title = "Locations";
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.title = `${title} - Serious Sanji`;
  }, []);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '5px solid #ff4444',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <PageTitleContainer title={title} backgroundImage={backgroundImage} />
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{
          py: { xs: "50px", sm: "50px", md: "60px" },
          px: { xs: "40px", sm: "60px", md: "200px" },
        }}
      >
        <Grid
          item
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{
            width: "100%",
            padding: "0px !important",
            margin: "0px !important",
          }}
        >
          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                North Miami
              </Typography>
              <Typography variant="body1">13200 Biscayne Blvd, North Miami FL 33181</Typography>
            </CardContent>
            <CardActions>
              <a href="https://goo.gl/maps/gUmQwdr2bAEge5Fx9" target="_blank" alt="Direction to North Miami Serious Sanji" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <Button size="small" className="locationCardButton" variant="outlined">
                  Direction
                </Button>
              </a>
              <Button onClick={()=>{setOpen(true)}} size="small" className="locationCardButton" variant="outlined" style={{marginLeft: '8px'}}>
                Menu
              </Button>
              <Modal
                open={open}
                onClose={()=>{setOpen(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box sx={{ ...modalStyle, width: '80%', height: '100%', overflow:'scroll', textAlign: 'center' }}>
                  <Button onClick={()=>{setOpen(false)}} size="small" variant="outlined" style={{margin: '10px'}}>
                    Exit Menu
                  </Button>
                  <img src={menuOne} alt="Menu 1" style={{width: '100%'}}/>
                  <img src={menuTwo} alt="Menu 2" style={{width: '100%'}}/>
                </Box>
              </Modal>
              <a href="tel:3056038938" alt="call North Miami Serious Sanji" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <Button size="small" className="locationCardButton" variant="outlined">
                  305-603-8938
                </Button>
              </a>
            </CardActions>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Davie
              </Typography>
              <Typography variant="body1">6419 Stirling Rd, Davie, FL 33314</Typography>
            </CardContent>
            <CardActions>
              <a href="https://goo.gl/maps/v7xspPZohTZTjbge6" target="_blank" alt="Direction to Davie Serious Sanji" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <Button size="small" className="locationCardButton" variant="outlined">
                  Direction
                </Button>
              </a>
            </CardActions>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Boca Raton
              </Typography>
              <Typography variant="body1">9845 Glades Rd, Boca Raton FL 33434</Typography>
            </CardContent>
            <CardActions>
              <a href="https://goo.gl/maps/tkPuE2ndW8nWBPXc6" target="_blank" alt="Direction to Boca Raton Serious Sanji" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <Button size="small" className="locationCardButton" variant="outlined">
                  Direction
                </Button>
              </a>
            </CardActions>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Plantation
              </Typography>
              <Typography variant="body1">7626 Peters Rd, Plantation, FL 33324, USA</Typography>
            </CardContent>
            <CardActions>
              <a href="https://maps.app.goo.gl/Sb7iuqdYnyx9sfPB9" target="_blank" alt="Direction to Boca Raton Serious Sanji" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <Button size="small" className="locationCardButton" variant="outlined">
                  Direction
                </Button>
              </a>
            </CardActions>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Coral Spring
              </Typography>
              <Typography variant="body1">929 N University Dr, Coral Springs. FL 33071</Typography>
            </CardContent>
            <CardActions>
              <a href="https://maps.app.goo.gl/Fr6MeD3nbAcLFNUU8" target="_blank" alt="Direction to Coral Spring Serious Sanji" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <Button size="small" className="locationCardButton" variant="outlined">
                  Direction
                </Button>
              </a>
            </CardActions>
          </Card>
        </Grid>
        <Grid
          item
          md={6}
          order={{ xs: 1, md: 2 }}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            color: "white",
            pb: "30px",
            pl: { xs: "0px !important", md: "15px !important" },
          }}
        >

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Wisconsin
              </Typography>
              <Typography variant="body1">853 N Mayfair RdWauwatosa, WI 53226 </Typography>
            </CardContent>
            <CardActions>
              <a href="https://maps.app.goo.gl/zKTiZjAJyRkGpK5QA" target="_blank" alt="Direction to Wisconsin Serious Sanji" rel="noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <Button size="small" className="locationCardButton" variant="outlined">
                  Direction
                </Button>
              </a>
            </CardActions>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Fort Lauderdale
              </Typography>
              <Typography variant="body1">Coming Soon</Typography>
            </CardContent>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Boynton Beach
              </Typography>
              <Typography variant="body1">Coming Soon</Typography>
            </CardContent>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Miami
              </Typography>
              <Typography variant="body1">Coming Soon</Typography>
            </CardContent>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Chicago
              </Typography>
              <Typography variant="body1">Coming Soon</Typography>
            </CardContent>
          </Card>

          <Card className="locationCard">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Minnesota
              </Typography>
              <Typography variant="body1">Coming Soon</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Locations;
